<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <v-row>
          <v-col cols="4">
            <v-autocomplete
              v-model="competencia"
              :items="competencias"
              item-key="id"
              item-text="competencia"
              return-object
              multiple
              @change="filters"
              label="Competencia"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="grupo"
              :items="grupos"
              item-key="id"
              item-text="grupo"
              return-object
              multiple
              @change="filters"
              label="Grupo"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="externo"
              :items="externos"
              item-key="id"
              item-text="externo"
              return-object
              @change="filters"
              label="Externo"
              autocomplete="new-password"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-data-table
      v-model="regionesSelected"
      :headers="headersRegiones"
      :items="regionesFiltered"
      :search="searchRegion"
      show-select
      @input="getComunas()"
      item-key="id_region"
      class="table-striped elevation-1 mb-8 rounded"
      hide-default-footer
      disable-pagination
      id="region_table"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded">
          <v-toolbar-title>Regiones: {{ regiones.length }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="searchRegion"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-btn
            color="primary float-right"
            dark
            class="mb-2 ml-5"
            v-on:click="downloadExcelFile('cobertura')"
          >
            Exportar
          </v-btn>
        </v-toolbar>
      </template>
      <template slot="body.append">
        <tr
          class="font-weight-bolder"
          v-if="Object.keys(regionesFiltered).length > 0"
        >
          <td></td>
          <td>Total</td>
          <td class="text-center">
            {{ regionesFiltered.reduce((s, a) => s + a.comunas.length, 0) }}
          </td>
          <td class="text-center">
            {{ regionesFiltered.reduce((s, a) => s + a.jobbers, 0) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      v-model="comunasSelected"
      :headers="headersComunas"
      :items="comunasFiltered"
      :search="searchComuna"
      show-select
      @input="getJobbers()"
      item-key="id_comuna"
      class="table-striped elevation-1 mb-8 rounded"
      id="comuna_table"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded">
          <v-toolbar-title>Comunas: {{ comunas.length }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="searchComuna"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:[`item.jobbers`]="{ item }">
        {{ item.jobbers.length }}
      </template>
      <template slot="body.append">
        <tr
          class="font-weight-bolder"
          v-if="Object.keys(comunasFiltered).length > 0"
        >
          <td></td>
          <td>Total</td>
          <td>{{ comunasFiltered.length }}</td>
          <td class="text-center">
            {{ comunasFiltered.reduce((s, a) => s + a.jobbers.length, 0) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      :headers="headersJobbers"
      :items="jobbersFiltered"
      :search="searchJobber"
      class="table-striped elevation-1 mb-8 rounded"
      id="jobber_table"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded">
          <v-toolbar-title>
            Jobbers: {{ jobbersFiltered.length }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-col cols="4">
            <v-text-field
              v-model="searchJobber"
              append-icon="search"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template slot="body.append">
        <tr
          class="font-weight-bolder"
          v-if="Object.keys(jobbersFiltered).length > 0"
        >
          <td></td>
          <td>Total</td>
          <td v-for="index in 3" :key="index"></td>
          <td class="text-center">
            {{ jobbersFiltered.reduce((s, a) => s + Number(a.puntaje), 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFiltered.reduce((s, a) => s + a.tareasAsignadas, 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFiltered.reduce((s, a) => s + a.tareasRealizadas, 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFiltered.reduce((s, a) => s + a.tareasPendientes, 0) }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      :headers="headersJobbersAll"
      :items="jobbersFilteredAll"
      item-key="index"
      hide-default-footer
      disable-pagination
      class="d-none"
      id="all_jobber_table"
    >
      <template slot="body.append">
        <tr
          class="font-weight-bolder"
          v-if="Object.keys(jobbersFilteredAll).length > 0"
        >
          <td>Total</td>
          <td v-for="index in 7" :key="index"></td>
          <td class="text-center">
            {{ jobbersFilteredAll.reduce((s, a) => s + Number(a.puntaje), 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFilteredAll.reduce((s, a) => s + a.tareasAsignadas, 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFilteredAll.reduce((s, a) => s + a.tareasRealizadas, 0) }}
          </td>
          <td class="text-center">
            {{ jobbersFilteredAll.reduce((s, a) => s + a.tareasPendientes, 0) }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { _ } from "vue-underscore";
import "moment/locale/es";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import {
  GET_USUARIO_GRUPOS,
  GET_COMPETENCIAS,
  GET_COVERAGE,
} from "@/core/services/store/jobber.module";
import { GET_REGIONES } from "@/core/services/store/global.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
export default {
  name: "jobberCoverage",
  data() {
    return {
      grupos: [],
      grupo: [],
      competencias: [],
      competencia: [],
      externos: [
        {
          id: "",
          externo: "",
        },
        {
          id: "1",
          externo: "Si",
        },
        {
          id: "2",
          externo: "No",
        },
      ],
      externo: [],
      headersRegiones: [
        {
          text: "Región",
          value: "region",
        },
        {
          text: "Comunas",
          value: "cantidadComunas",
          align: "center",
        },
        {
          text: "Jobbers",
          value: "jobbers",
          align: "center",
        },
      ],
      regiones: [],
      regionesFiltered: [],
      regionesSelected: [],
      searchRegion: "",
      headersComunas: [
        {
          text: "Región",
          value: "region",
        },
        {
          text: "Comuna",
          value: "comuna",
        },
        {
          text: "Jobbers",
          value: "jobbers",
          align: "center",
        },
      ],
      comunas: [],
      comunasFiltered: [],
      comunasSelected: [],
      searchComuna: "",
      headersJobbers: [
        {
          text: "ID",
          value: "id",
          align: "center",
          width: "5em",
        },
        {
          text: "Jobber",
          value: "nombre",
          width: "16em",
        },
        {
          text: "Celular",
          value: "telefono",
          align: "center",
          width: "8em",
        },
        {
          text: "Grupo",
          value: "grupo",
          width: "8em",
        },
        {
          text: "Externo",
          value: "externo",
          align: "center",
          width: "8em",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
          width: "8em",
        },
        {
          text: "Asignadas",
          value: "tareasAsignadas",
          align: "center",
          width: "10em",
        },
        {
          text: "Realizadas",
          value: "tareasRealizadas",
          align: "center",
          width: "10em",
        },
        {
          text: "En Proceso",
          value: "tareasPendientes",
          align: "center",
          width: "10em",
        },
      ],
      headersJobbersAll: [
        {
          text: "Región",
          value: "region",
          width: "8em",
        },
        {
          text: "Comuna",
          value: "comuna",
          width: "9em",
        },
        {
          text: "ID",
          value: "id",
          align: "center",
          width: "5em",
        },
        {
          text: "Jobber",
          value: "nombre",
          width: "16em",
        },
        {
          text: "Celular",
          value: "telefono",
          align: "center",
          width: "8em",
        },
        {
          text: "Competencia",
          value: "competencia",
          width: "11em",
        },
        {
          text: "Grupo",
          value: "grupo",
          width: "8em",
        },
        {
          text: "Externo",
          value: "externo",
          align: "center",
          width: "8em",
        },
        {
          text: "Puntaje",
          value: "puntaje",
          align: "center",
          width: "8em",
        },
        {
          text: "Asignadas",
          value: "tareasAsignadas",
          align: "center",
          width: "10em",
        },
        {
          text: "Realizadas",
          value: "tareasRealizadas",
          align: "center",
          width: "10em",
        },
        {
          text: "En Proceso",
          value: "tareasPendientes",
          align: "center",
          width: "10em",
        },
      ],
      jobbers: [],
      jobbersFilteredAll: [],
      jobbersFiltered: [],
      searchJobber: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Cobertura Jobber", route: "jobber-coverage" },
    ]);
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getRegiones();
      this.getUsuarioGrupos();
      this.getCompetencias();
    },
    getUsuarioGrupos() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_USUARIO_GRUPOS).then((grupos) => {
        oVue.grupos = grupos;
        KTUtil.removeLoading();
      });
    },
    getCompetencias() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_COMPETENCIAS).then((competencias) => {
        oVue.competencias = competencias;
        KTUtil.removeLoading();
      });
    },
    getRegiones() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store.dispatch(GET_REGIONES).then((regiones) => {
        oVue.regiones = _.chain(regiones)
          .groupBy("id_region")
          .map(function (value, index) {
            return {
              id_region: index,
              region: value[0].region,
              cantidadComunas: value.length,
              jobbers: 0,
              comunas: value,
            };
          })
          .value();
        // .sort((a,b) => a.region.localeCompare(b.region))
        KTUtil.removeLoading();
        oVue.filterJobberRegiones();
      });
    },
    filterJobberRegiones() {
      let oVue = this;
      oVue.regionesFiltered = oVue.regiones.map((r) => {
        let jobbersArray = [];
        const jobbersComunas = r.comunas.map((jc) => jc.jobbers);
        jobbersComunas.forEach((jc) => {
          if (!jc) {
            return;
          }
          const jobbers = JSON.parse(jc);
          jobbers.forEach((jobber) => {
            if (!jobbersArray.includes(jobber.id)) {
              if (oVue.validateJobber(jobber)) {
                jobbersArray.push(jobber.id);
              }
            }
          });
        });
        return {
          id_region: r.id_region,
          region: r.region,
          cantidadComunas: r.cantidadComunas,
          comunas: r.comunas,
          jobbers: jobbersArray.length,
        };
      });
    },
    getComunas() {
      let oVue = this;
      KTUtil.setLoading();
      if (oVue.regionesSelected.length > 0) {
        oVue.comunas = []
          .concat(...oVue.regionesSelected.map((r) => r.comunas))
          .map((c) => {
            return {
              id_region: c.id_region,
              region: c.region,
              id_provincia: c.id_provincia,
              provincia: c.provincia,
              id_comuna: c.id_comuna,
              comuna: c.comuna,
              jobbers: c.jobbers,
            };
          });
      } else {
        oVue.comunas = [];
        oVue.comunasSelected = [];
      }
      KTUtil.removeLoading();
      oVue.filterJobberComunas();
      oVue.getJobbers();
    },
    filterJobberComunas() {
      let oVue = this;
      oVue.comunasFiltered = oVue.comunas.map((c) => {
        let jobbersArray = [];
        if (c.jobbers) {
          const jobbers = JSON.parse(c.jobbers);
          jobbers.forEach((jobber) => {
            if (!jobber) {
              return;
            }
            if (!jobbersArray.includes(jobber.id)) {
              if (oVue.validateJobber(jobber)) {
                jobbersArray.push(jobber.id);
              }
            }
          });
        } else {
          jobbersArray = [];
        }
        return {
          id_region: c.id_region,
          region: c.region,
          id_provincia: c.id_provincia,
          provincia: c.provincia,
          id_comuna: c.id_comuna,
          comuna: c.comuna,
          jobbers: jobbersArray,
        };
      });
    },
    filters() {
      this.filterJobberRegiones();
      this.filterJobberComunas();
      this.filterJobbers();
    },
    condCompetencia() {
      return this.competencia.length;
    },
    condGrupo() {
      return this.grupo.length;
    },
    condExterno() {
      return Object.keys(this.externo).length > 0 && this.externo.id != "";
    },
    condRegion() {
      return this.regionesSelected && this.regionesSelected.length > 0;
    },
    condComuna() {
      return this.comunasSelected && this.comunasSelected.length > 0;
    },
    validateJobber(jobber) {
      let oVue = this;
      if (oVue.condCompetencia() || oVue.condGrupo() || oVue.condExterno()) {
        const competencias = oVue.competencia.map((c) => c.id);
        const jobberCompetencias = jobber.id_competencias
          ? jobber.id_competencias.split(",")
          : [];
        const competenciaCond = oVue.condCompetencia()
          ? competencias.some((c) => jobberCompetencias.includes(c.toString()))
          : true;
        const grupoCond = oVue.condGrupo()
          ? oVue.grupo.some((g) => g.id == jobber.id_grupo)
          : true;
        const externoCond = oVue.condExterno()
          ? oVue.externo.id == "1"
            ? jobber.id_empresa_externa != null
            : jobber.id_empresa_externa == null
          : true;
        if (competenciaCond && grupoCond && externoCond) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    async getJobbers() {
      let oVue = this;
      oVue.jobbers = [];
      oVue.jobbersFilteredAll = [];
      oVue.jobbersFiltered = [];
      const comuna = oVue.condComuna()
        ? oVue.comunasSelected
            .map(function (a) {
              return a.id_comuna;
            })
            .join(", ")
        : null;
      if (!comuna) {
        return;
      }
      KTUtil.setLoading();
      const region = oVue.condRegion()
        ? oVue.regionesSelected
            .map(function (a) {
              return a.id_region;
            })
            .join(", ")
        : null;
      const provincia = oVue.condComuna()
        ? oVue.comunasSelected
            .map(function (a) {
              return a.id_provincia;
            })
            .join(", ")
        : null;

      let aData = {
        region,
        provincia,
        comuna,
        grupo: null,
        competencia: null,
        externo: null,
      };
      oVue.$store.dispatch(GET_COVERAGE, aData).then((jobbers) => {
        oVue.jobbers = jobbers;
        KTUtil.removeLoading();
        oVue.filterJobbers();
      });
    },
    filterJobbers() {
      let oVue = this;
      const comuna = oVue.condComuna()
        ? oVue.comunasSelected
            .map(function (a) {
              return a.id_comuna;
            })
            .join(", ")
        : null;
      if (!comuna) {
        return;
      }
      const comunasSelected = oVue.comunasFiltered.filter((c) => {
        return oVue.comunasSelected.some((cc) => c.id_comuna == cc.id_comuna);
      });
      let jobbersFilteredAll = [];
      let jobbersFiltered = [];
      oVue.jobbers.forEach((jobber, index) => {
        const condJobber = comunasSelected.some((c) =>
          c.jobbers ? c.jobbers.includes(jobber.id) : false
        );
        if (condJobber) {
          jobber = {
            index,
            ...jobber,
          };
          const condJobberDistinct = jobbersFiltered.some(
            (c) => c.id == jobber.id
          );
          if (!condJobberDistinct) {
            jobbersFiltered.push(jobber);
          }
          const competencias = oVue.competencia.map((c) => c.id);
          const competenciaCond = oVue.condCompetencia()
            ? competencias.includes(jobber.id_competencia)
            : true;
          if (competenciaCond) {
            jobbersFilteredAll.push(jobber);
          }
        }
      });
      oVue.jobbersFilteredAll = jobbersFilteredAll;
      oVue.jobbersFiltered = jobbersFiltered;
    },
    downloadExcelFile(filename) {
      let oVue = this;
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());

      const region = oVue.condRegion()
        ? oVue.regionesSelected.map((r) => r.region).join(", ")
        : "Todos";
      const provincia =
        oVue.condComuna() != ""
          ? [...new Set(oVue.comunasSelected.map((c) => c.provincia))].join(
              ", "
            )
          : "Todos";
      const comuna = oVue.condComuna()
        ? oVue.comunasSelected.map((c) => c.comuna).join(", ")
        : "Todos";
      const competencia = oVue.condCompetencia()
        ? oVue.competencia
            .map(function (a) {
              return a.competencia;
            })
            .join(", ")
        : "Todos";
      const grupo = oVue.condGrupo()
        ? oVue.grupo
            .map(function (a) {
              return a.grupo;
            })
            .join(", ")
        : "Todos";
      const externo = oVue.condExterno() ? oVue.externo.externo : "Todos";

      const infoFilters = [
        ["Región", region],
        ["Provincia", provincia],
        ["Comuna", comuna],
        ["Competencia", competencia],
        ["Grupo", grupo],
        ["Externo", externo],
      ];

      const ws_sheet_name = "filtros";
      const ws_sheet = XLSX.utils.aoa_to_sheet(infoFilters);
      wb.SheetNames.push(ws_sheet_name);
      wb.Sheets[ws_sheet_name] = ws_sheet;

      const sheets = [
        {
          id: "region_table",
          cols: oVue.headersRegiones.length,
          sheet: "regiones",
          hide_first: true,
        },
        {
          id: "comuna_table",
          cols: oVue.headersComunas.length,
          sheet: "comunas",
          hide_first: true,
        },
        {
          id: "all_jobber_table",
          cols: oVue.headersJobbersAll.length,
          sheet: "jobbers",
          hide_first: false,
        },
      ];

      sheets.forEach((s) => {
        const elt = KTUtil.getById(s.id);
        const table = elt.querySelector("table");
        const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
          raw: true,
        }));
        ws["!cols"] = [];
        if (s.hide_first) ws["!cols"][0] = { hidden: true };
        const row = 0;
        for (let col = 0; col <= s.cols; col++) {
          const ref = XLSX.utils.encode_cell({ r: row, c: col });
          if (ws[ref]) {
            ws[ref].s = {
              font: {
                bold: true,
              },
            };
          }
        }
        XLSX.utils.book_append_sheet(wb, ws, s.sheet);
      });

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
};
</script>
